
.container {
    width: 500px;
    margin: auto;
    margin-top: 100px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    text-align: center;
  }

  .form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }


